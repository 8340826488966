@media only screen and (min-width: 768px) {


  /* ---------- Header ---------- */

  .header-logo {
    height: 40px;
  }




  /* ---------- Swiper ---------- */

  .banner {
    /* margin-top: 80px; */
    height: 400px;
  }

  /* .home_featured .swiper-wrapper {
    height: 450px;
  } */

  .about_cards_position {
    position: relative;
    /* top: -50px; */
  }

  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 35px !important;
  }




  /* ---------- Home ---------- */

  .home-head-1 {
    font-size: 23px;
  }

  .home-head-2 {
    font-size: 10px;
  }

  .home-stick {
    width: 150px;
  }

  .home-categories-swiper {
    height: 250px;
  }



  /* ---------- Categories ---------- */

  .categories-banner {
    height: 300px;
  }

  .categories-images {
    grid-template-columns: auto auto auto;
    gap: 30px;
    margin-inline: 30px;
  }




  /* ---------- Products ---------- */

  .products-banner {
    height: 300px;
  }

  .products {
    grid-template-columns: auto auto auto;
    gap: 30px;
    margin-inline: 30px;
  }

  .products1 {
    grid-template-columns: auto auto auto;
    gap: 20px;
    margin-inline: 20px;
  }

  .products2 {
    grid-template-columns: auto auto auto;
    gap: 20px;
    margin-inline: 20px;
  }



  /* ---------- Product ---------- */

  .product-swiper-1 {
    height: 63vh;
  }

  .product-swiper-2 {
    height: 17vh;
  }

  .product-detail {
    height: 100vh;
  }

  .product-image-ads {
    width: 50%;
  }




  /* ---------- About ---------- */

  .about-banner {
    height: 50vh;
  }

  .our-story {
    width: 75%;
    margin-top: 75px;
  }

  .our-story-img {
    width: 50%;
  }

  .our-story-content {
    width: 50%;
    padding-top: 0;
    padding-inline: 0;
  }

  .our-story-img-1 {
    width: 60%;
  }

  .our-story-img-2 {
    width: 40%;
    top: 15%;
    right: 7%;
  }


  .our-mission {
    width: 75%;
    margin-top: 100px;
  }

  .our-mission-img {
    width: 50%;
  }

  .our-mission-content {
    width: 50%;
    padding-top: 0;
    padding-inline: 0;
  }

  .our-mission-img-1 {
    width: 80%;
  }





  /* ---------- Footer 2 ---------- */

  .footer-2-text {
    font-size: 15px;
  }



}