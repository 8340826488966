@media only screen and (min-width: 576px) {


  /* ---------- Header ---------- */

  .headerLogo {
    height: 37px;
  }

  .about_cards_position {
    position: relative;
    /* top: -50px; */
  }



  /* ---------- Home ---------- */

  /* .home-categories-swiper {
    height: 225px;
  } */


  /* ---------- Products ---------- */

  .products {
    grid-template-columns: auto auto;
    gap: 30px;
    margin-inline: 30px;
  }

  .products1 {
    grid-template-columns: auto auto auto;
    gap: 15px;
    margin-inline: 15px;
  }

  .products2 {
    grid-template-columns: auto auto;
    gap: 15px;
    margin-inline: 15px;
  }



  /* ---------- Product ---------- */

  .product-swiper-1 {
    height: 50vh;
  }

  .product-swiper-2 {
    height: 15vh;
  }

  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 30px !important;
  }




  /* ---------- About ---------- */

  .about-banner {
    height: 40vh;
  }

  .our-story {
    margin-top: 13%;
  }

  .our-story-content,
  .our-mission-content {
    padding-top: 15%;
    padding-inline: 13%;
  }

  .our-story-img-1 {
    width: 45%;
  }

  .our-story-img-2 {
    position: relative;
    width: 30%;
    top: 75px;
    right: 5%;
  }

  .our-mission {
    margin-top: 13%;
  }


  .our-mission-content {
    padding-top: 7%;
    padding-inline: 13%;
  }

  .our-mission-img-1 {
    width: 60%;
  }





  /* ---------- Categories ---------- */

  .categories-images {
    gap: 25px;
    margin-inline: 25px;
  }




}