@media only screen and (min-width: 0px) {


  .scrollbar-hidden::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }

  /* ---------- Header ---------- */

  .header-blur {
    backdrop-filter: blur(20px);
  }

  .headerLogo {
    /* height: 35px; */
    height: 37px;
  }

  .offcanvas-logo {
    height: 40px;
  }

  /* .home_featured .swiper-wrapper {
    height: 340px;
  } */

  .about_cards_position {
    position: relative;
    /* top: -50px; */
  }

  /* ---------- Swiper ---------- */

  .banner {
    /* margin-top: 66px; */
    height: 250px;
  }
  
  .swiper-button-prev, .swiper-button-next {
    color: white !important;
  }

  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 25px !important;
  }


  /* ---------- Home ---------- */

  .home-head-1 {
    font-size: 20px;
  }

  .home-head-2 {
    font-size: 7px;
  }

  .home-stick {
    width: 125px;
  }

  .home-categories-swiper {
    height: 175px;
    width: 80%;
  }

  .home-ads-2 {
    width: 43%;
  }

  .see_all_btn {
    padding: 2px 5px;
  }

  .home_categories .swiper-slide {
    height: 25vh;
  }





  /* ---------- Categories ---------- */

  .categories-banner {
    height: 250px;
  }

  .categories-images {
    display: grid;
    grid-template-columns: auto auto;
    gap: 17px;
    margin-inline: 17px;
  }

  .products-product { 
    height: 40vh;
  }

  /* .categories-image {
    width: 40%;
  } */



  /* ---------- Products ---------- */

  .products-banner {
    height: 250px;
  }

  .products {
    display: grid;
    grid-template-columns: auto;
    gap: 30px;
    margin-inline: 30px;
  }


  .products1 {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    margin-inline: 10px;
  }

  .products2 {
    display: grid;
    grid-template-columns: auto;
    gap: 30px;
    margin-inline: 30px;
  }





  /* ---------- Product ---------- */

  .product-swiper-1 {
    height: 40vh;
  }

  .product-swiper-2 {
    height: 10vh;
  }

  .product-hr-1 {
    width: 95%;
  }

  .product-images-ads {
    width: 100%;
  }

  .product-image-ads {
    width: 90%;
  }




  /* ---------- About ---------- */

  .about-banner {
    height: 30vh;
  }


  .our-story {
    /* border: 1px solid black; */
    margin-top: 25%;
  }

  .our-story-img {
    /* border: 1px solid red; */
    text-align: center;
  }

  .our-story-content {
    /* border: 1px solid blue; */
    padding-top: 17%;
    padding-inline: 10%;
  }

  .our-story-img-1 {
    width: 47%;
  }

  .our-story-img-2 {
    position: relative;
    width: 33%;
    top: 50px;
    right: 5%;
  }


  .our-mission {
    /* border: 1px solid black; */
    margin-top: 20%;
  }

  .our-mission-img {
    /* border: 1px solid red; */
    text-align: center;
  }

  .our-mission-content {
    /* border: 1px solid blue; */
    padding-top: 5%;
    padding-inline: 10%;
  }

  .our-mission-img-1 {
    width: 80%;
  }

  .our-mission-img-2,
  .our-mission-img-3 {
    width: 100px;
  }


  .value {
    width: 100%;
  }

  .value-img-1 {
    width: 40%;
    position: relative;
    right: 10px;
  }

  .value-img-2 {
    width: 40%;
    position: relative;
    right: 10px;
  }

  .ul-dot-green::marker {
    color: green;
  }



  /* ---------- Footer 2 ---------- */

  .footer-2-text {
    font-size: 12px;
  }




  /* ---------- Check ---------- */

  .footer-logo {
    height: 100px;
  }


  .cursor-pointer {
    cursor: pointer;
  }







  /* ---------- Customer Review Popup ---------- */

  .modal-header .btn-close {
    margin: 0px;
  }

  .write-review-otp {
    width: 25% !important;
    height: 50px;
    font-size: 25px;
  }








  .custom-dots li button:before {
    font-size: 10px;
    color: blue;
  }
  
  .custom-dots li.slick-active button:before {
    color: red;
  }
  
  .custom-dots li {
    width: auto;
  }
  
  .custom-dots li div {
    width: 50px;
    height: 50px;
    overflow: hidden;
  }

  .reactSlick {
    width: 75%;
  }

  .reactSlick .slick-list {
    height: 300px;
  }

  .reactSlick .slick-dots {
    position: relative;
    bottom: 0px;
  }

  .reactSlick .slick-dots li {
    width: 25%;
    margin: 0px;
  }

  



}