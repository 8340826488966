@media only screen and (min-width: 1400px) { 



  /* ---------- Products ---------- */

  .products {
    grid-template-columns: auto auto auto auto auto;
    gap: 50px;
    margin-inline: 50px;
  }

  .products1 {
    grid-template-columns: auto auto auto auto auto auto;
    gap: 35px;
    margin-inline: 35px;
  }

  .products2 {
    grid-template-columns: auto auto auto auto auto auto;
    gap: 35px;
    margin-inline: 35px;
  }






  /* ---------- Product ---------- */

  .product-image-ads {
    width: 40%;
  }

  .product-images-ads {
    width: 80%;
  }

  .product-review {
    width: 70%;
  }




  /* ---------- Categories ---------- */

  .categories-images {
    grid-template-columns: auto auto auto auto auto;
    gap: 50px;
    margin-inline: 50px;
  }





  /* ---------- About ---------- */

  .our-story, .our-mission {
    width: 70%;
  }

  .our-story-content, .our-mission-content {
    padding-right: 10%;
  }


  .our-mission-img {
    text-align: start;
  }

  .our-mission-img-1 {
    width: 50%;
  }

  .value {
    height: 60vh;
  }




  
}