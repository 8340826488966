* {
  margin: 0px;
  padding: 0px;
}

p {
  margin-bottom: 0px !important;
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.review_slider .swiper-wrapper {
  /* height: 260px; */
  padding-top: 50px;
}

.home_swiper .swiper-wrapper {
  height: 250px;
}

.home_featured .swiper-wrapper {
  /* height: 380px; */
  margin-bottom: 1.5rem;
}

small {
  font-size: 12px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.swiper-pagination-bullet {
  height: 5px !important;
}

.swiper-pagination-bullet-active {
  background-color: orange !important;
  width: 20px !important;
  height: 5px !important;
  border-radius: 5px !important;
}





body {
  background-image: url('https://navkar-user.vercel.app/images/background.png');
  position: relative;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.home_categories .swiper-wrapper {
  /* padding-bottom: 0px; */
  margin-bottom: 1.5rem;
}

.home_categories .swiper-pagination-bullets.swiper-pagination-horizontal {
  /* display: none; */
  height: 10px;
}

.home_products .swiper-wrapper {
  /* padding-bottom: 0px; */
  margin-bottom: 1.5rem;
}


.img_details {
  position: absolute;
  bottom: 0px;
  padding: 0px 15px;
  border-radius: 15px;
  transition: .2s ease-in;
  transform: translateY(50px);
  opacity: 0;
  height: 120px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.612), black);
}

.img_details:hover {
  opacity: 1;
  transform: translateY(0px);
}

.img_last {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.612), black);
  position: absolute;
  bottom: 0px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  width: 100%;
  color: white;
  padding: 10px 12px;
  /* background-color: rgba(0, 0, 0, 0.41); */
}

.card_width_1 {
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.card_width_2 {
  width: 100%;
}


.cate_img {
  transition: 0.2s ease-in;
  cursor: pointer;
  /* filter: brightness(0.8); */
}


.cate_img:hover+.img_last {
  display: none;

}

.cate_img:hover~.img_details {
  transform: translateY(-0px);
  opacity: 1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}


.img_name {
  margin-top: 30px !important;
}

.cate {
  translate: 0px 0px;
}


.img_details:hover {
  translate: 0px 0px;
}

.sticker_1,
.sticker_2 {
  position: absolute;
  z-index: 10;
  top: -100px;
}

.sticker_1 {
  left: -3%;
  top: -160px;
}

.sticker_2 {
  right: 0px;
}


.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.see_all_btn {
  border: 2px solid snow;
  border-radius: 20px;
  padding: 4px 10px;
  color: snow;
  font-size: 12px;
  background-color: transparent;
}

.pro_see_all {
  border: 2px solid orange;
  border-radius: 10px;
  padding: 4px 15px;
  color: orange;
  font-size: 14px;
}

.pro_see_all a {
  color: orange;
  text-decoration-line: none;
}

/* Tab itmes  */

.feature_tabs .nav-tabs {
  display: flex;
  gap: 10%;
  justify-content: center;
}

.feature_tabs .nav-item,
.nav-link {
  font-weight: 600;
  border: none !important;
  color: black !important;
}

.feature_tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent !important;
  color: green !important;
  border-bottom: 2px solid green !important;
}


.order-btn {
  border: 1.5px solid green;
  border-radius: 20px;
  padding: 2px 8px;
  font-size: 12px;
  color: green;
  background-color: transparent;
  cursor: pointer;
}

.cate_w1,
.cate_w2 {
  cursor: pointer;
  border: 1px solid orange;
  padding: 1px 5px;
  font-size: 8px;
  border-radius: 10px;
}

.cate_w1 {
  background-color: orange !important;
  color: white !important;
}

.cate_w2 {
  background-color: white;
  color: orange !important;
}

.review_img {
  position: absolute;
  top: -37px;
  border-radius: 50%;
  z-index: 10;
  /* left: 30%; */
}

.review_img img {
  border-radius: 50%;
  /* border: 1px solid black; */
}

.review_slide {
  /* height: auto !important; */
  background-color: transparent !important;
}

.review_card {
  padding: 37px 15px;
  background-color: #F9F2E5;
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.about_banner {
  background-image: url("https://dostguru.com/HTML/flavoro/assets/img/s1.jpg");
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
  filter: brightness(0.2);
}

.about_cards_position {
  position: absolute;
  top: -50px;
}

.about_card {
  width: 200px;
  height: 150px;

  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0px 10px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.about_card_img {
  padding: 10px;
  margin: 5px 0px;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

textarea {
  resize: none;
}