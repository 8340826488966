@media only screen and (min-width: 1200px) {


  /* ---------- Header ---------- */

  .header-logo {
    height: 50px;
  }




  /* ---------- Home ---------- */

  .home-categories-swiper {
    height: 300px;
  }

  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 50px !important;
  }





  /* ---------- Products ---------- */

  .products {
    grid-template-columns: auto auto auto auto;
    gap: 45px;
    margin-inline: 45px;
  }

  .products1 {
    grid-template-columns: auto auto auto auto auto;
    gap: 30px;
    margin-inline: 30px;
  }

  .products2 {
    grid-template-columns: auto auto auto auto auto;
    gap: 30px;
    margin-inline: 30px;
  }





  /* ---------- Product ---------- */

  .product-review {
    width: 80%;
  }






  /* ---------- About ---------- */

  .our-story-img-1 {
    width: 50%;
  }

  .our-story-img-2 {
    width: 30%;
    top: 30%;
    right: 5%;
  }


  .our-mission-img-1 {
    width: 70%;
  }

  .value {
    height: 55vh;
  }





}