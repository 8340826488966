@media only screen and (min-width: 992px) {

  /* ---------- Header ---------- */

  .header-logo {
    height: 45px;
  }


  /* ---------- Swiper ---------- */

  .banner {
    /* margin-top: 80px; */
    height: 100vh;
  }

  /* .home_featured .swiper-wrapper {
    height: 370px;
  } */

  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 40px !important;
  }





  /* ---------- Home ---------- */

  .home-head-1 {
    font-size: 25px;
  }

  .home-head-2 {
    font-size: 13px;
  }

  .home-stick {
    width: 175px;
  }

  .home-categories-swiper {
    height: 275px;
  }


  /* ---------- Categories ---------- */

  .categories-banner {
    height: 50vh;
  }

  .categories-images {
    grid-template-columns: auto auto auto auto;
    gap: 35px;
    margin-inline: 35px;
  }



  /* ---------- Products ---------- */

  .products-banner {
    height: 50vh;
  }

  .products {
    grid-template-columns: auto auto auto auto;
    gap: 40px;
    margin-inline: 40px;
  }

  .products1 {
    grid-template-columns: auto auto auto auto;
    gap: 25px;
    margin-inline: 25px;
  }

  .products2 {
    grid-template-columns: auto auto auto auto;
    gap: 25px;
    margin-inline: 25px;
  }







  /* ---------- About ---------- */

  .about-banner {
    height: 100vh;
  }

  .our-story-img {
    width: 50%;
  }

  .our-story-content, .our-mission-content {
    width: 50%;
  }

  .our-story-img-1 {
    width: 55%;
  }

  .our-story-img-2 {
    width: 35%;
    top: 23%;
    right: 5%;
  }


  .value {
    height: 45vh;
  }

  .value-img-1 {
    height: 100%;
    width: auto;
    right: 50px;
  }

  .value-img-2 {
    height: 100%;
    width: auto;
    right: 50px;
  }

  .value-content {
    height: 100%;
  }





}